<template>
  <div class="goodcar">
    <el-container id="el-container">
      <my-header></my-header>
      <el-main id="el-main">
        <!-- 购物车div -->
        <div class="shop-car-list">
          <div class="shop-car-title">
            <span class="shop-car-title-txt">您的购物车有如下商品:</span>
          </div>
          <div class="shop-car-nav">
            <div class="shop-car-one">
              <input
                style="margin-left: 10px"
                type="checkbox"
                @click="clickcheckAll"
                v-model="checked"
              />
              <span class="checkbox-all" @click="clickcheckAll">全选</span>
            </div>
            <div class="shop-car-two">
              <span style="line-height: 40px; color: white; margin-left: 38%"
                >商品信息</span>
            </div>
            <div class="shop-car-three">
              <span style="line-height: 40px; color: white; margin-left: 39%"
                >单价</span
              >
            </div>
            <div class="shop-car-four">
              <span style="line-height: 40px; color: white; margin-left: 39%"
                >数量</span
              >
            </div>
            <div class="shop-car-five">
              <span style="line-height: 40px; color: white; margin-left: 39%"
                >小计</span
              >
            </div>
            <div class="shop-car-six">
              <span style="line-height: 40px; color: white; margin-left: 39%"
                >操作</span
              >
            </div>
          </div>

          <div class="nothing-div" v-if="!shopList[0]">
            <p class="nothing-txt">购物车中还没有商品,赶紧选购吧!</p>
          </div>

          <div
            class="shop-car-line"
            v-for="(item, index2) in shopList"
            :key="index2"
          >
            <!-- 多选框 -->
            <div style="width: 6%; height: 100%">
              <!-- 每个商品按钮 -->
              <input
                style="margin-left: 41%; margin-top: 80%"
                type="checkbox"
                @change="chooseOne(item)"
                v-model="checkModel"
                :value="item.id"
              />
            </div>
            <!-- 商品信息 -->
            <div style="display: flex; width: 33%; height: 100%">
              <div class="shop-car-line-box-img">
                <img :src="item.productMainImage" alt="" />
              </div>

              <div class="shop-car-line-name">
                <span class="shop-name">{{ item.productName }}</span>
              </div>
            </div>
            <!-- 单价 -->
            <div class="shop-car-onprice">
              <span class="shop-car-onprice-txt"
                >￥{{ item.productPrice }}</span
              >
            </div>
            <!-- 数量 -->
            <div class="el-number">
              <el-input-number
                class="num-stepper"
                size="small"
                @change="updateNum(item)"
                :min="1"
                v-model="item.quantity"
              ></el-input-number>
            </div>
            <!-- 小计 -->
            <div class="shop-car-allprice">
              <span>￥{{ item.productTotalPrice }}</span>
            </div>
            <!-- 操作 -->
            <div class="shop-car-delete">
              <span @click="deleteShopcar(item)">删除</span>
            </div>
          </div>
          <!-- 购物车底部 -->
          <div class="shop-car-list-bottom">
            <div class="shop-car-one">
              <input
                style="margin-left: 10px"
                type="checkbox"
                @click="clickcheckAll"
                v-model="checked"
              />
              <span class="checkbox-all-bottom" @click="clickcheckAll"
                >全选</span
              >
            </div>

            <div class="shop-delete-check">
              <span
                class="shop-delete-check-txt"
                v-text="`删除商品`"
                @click="deleteChoose"
              ></span>
            </div>

            <div class="shop-delete-check">
              <span class="shop-delete-check-num">已选择<span v-text="`${checkArray.length}`"></span>件商品</span>
            </div>
            <!-- 选中商品总价 -->
            <div class="shop-delete-check">
              <span class="shop-delete-check-num">总计:</span>
              <span
                class="shop-delete-check-allprice"
                v-text="`￥${allprice}`"
              ></span>
            </div>

            <div class="goPay">
              <span class="gopay-button" @click="goPay()">去结算</span>
            </div>
          </div>
        </div>
      </el-main>

      <el-footer></el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 购物车数量
      name: "",
      userinfo: JSON.parse(sessionStorage.getItem("userinfo")),
      checkAll: false,
      //  goodnums:'1',
      shopList: [], //购物车商品
      shopListlength: "", //购物车商品长度
      quantity: "", //购买数量
      allprice: "", //选中商品总价
      checked: "", //是否全选
      checkModel: [], //双向数据绑定的数组，我是用的id
      chooId: "", //选中商品的id  用逗号连接
      payList: [],
      orderId: "",
      toGoodlist: [],
      // 所有选中商品
      checkArray: [],
      
    };
  },
  watch: {
    // 按钮开关
    checkModel() {
      if (this.checkModel.length == this.shopListlength) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
  },
  methods: {
    // 查询商品---，目前没有使用
    // async goGoodList() {
    //   const res = await this.$http({
    //     method: "get",
    //     url: "front/product/findList?name=" + this.name + "&pageSize=15",
    //   });
    //   if (res.data.data) {
    //     this.toGoodlist = res.data.data.list;
    //     this.$router.push({
    //       path: "/goodList",
    //       query: { name: JSON.stringify(this.toGoodlist) },
    //     });
    //   }
    // },
    async getGoodShop() {
      const res = await this.$http({
        method: "get",
        url: "front/cart/findList?type=1",
      });
      if (res.data.data) {
        console.log(res.data.data,'204');
        this.shopList = res.data.data.cartProductVoList;
        this.shopListlength = res.data.data.cartProductVoList.length;
        this.allprice = res.data.data.cartTotalPrice;
        this.checked = res.data.data.allChecked;
        this.checkModel = [];
        this.checkArray = [];
        res.data.data.cartProductVoList.forEach((v, k) => {
          // console.log(v,'212')
          if (v.checked == 1) {
           
            this.checkModel.push(v.id);
            this.checkArray.push(v);
          }
        });
        this.$store.commit(
          "altershopnum",
          res.data.data.cartProductVoList.length
        );
      }
    },
    //  全选或反全选
    async clickcheckAll() {
      if (this.checked) {
        this.checkModel = [];
        //  反选
        const res = await this.$http({
          method: "post",
          url: "front/cart/unSelectAll?type=1",
          
        });
      } else {
        //  全选
        const res = await this.$http({
          method: "post",
          url: "front/cart/selectAll?type=1",
        });
      }
      this.getGoodShop();
    },
    //  选中或反选
    async chooseOne(item) {
      if (this.checkModel.indexOf(item.id) == -1) {
        const res = await this.$http({
          method: "post",
          url: "front/cart/unSelect",
          data: {
            productId: item.productId,
           
            type:'1',
          },
        });
        if (this.checkModel.length >= this.shopListlength) {
          this.checked = true;
        } else {
          this.checked = false;
        }
      } else {
        const res = await this.$http({
          method: "post",
          url: "front/cart/select",
          data: {
            productId: item.productId,
            
           type:'1',
          },
        });
      }

      this.getGoodShop();
    },
    //  删除单个购物车商品
    async deleteShopcar(item) {
      console.log(item.id)
      let a=[{
        productId: item.productId
      }]
      //  console.log(a=JSON.stringify(a))
      a=JSON.stringify(a)
      console.log(item,'276');
      const res = await this.$http({
        method: "post",
        url: "front/cart/delete",
        data: {
          productIds:a,
        },
      });
      this.getGoodShop();
      
    },
    // 修改商品数量
    async updateNum(item) {
      console.log(item,'291');
      const res = await this.$http({
        method: "post",
        url: "front/cart/update",
        data: {
          productId: item.productId,
          count: item.quantity
        },
       
      });
      this.getGoodShop();
    },
    
   
    // 删除选中
    async deleteChoose() {
      let data = [];
      this.checkArray.forEach((v, k) => {
        data.push({
          productId: v.productId
          
        });
      });
      const res = await this.$http({
        method: "post",
        url: "front/cart/delete",
        data: {
          productIds: JSON.stringify(data),
        },
      });
      this.getGoodShop();
    },
    async goPay() {
      const res = await this.$http({
        method: "post",
        url: "front/order/toShoppingList?type=1"
      });
      this.payList = res.data.data.omsOrderItems;
      this.orderId = res.data.data.totalPrice;
      if(!this.checked && this.checkArray == ''){
        
        this.$message({
        message: "请勾选您所想要的商品",
        
      });
      }else{
        console.log(this.checkArray,'343')
        this.$router.push({
        path: "/payList",
        query: {
          name: JSON.stringify(this.payList),
          host: JSON.stringify(this.orderId),
        },
      });
      }
         
    },
    myOrder() {
      const token = sessionStorage.getItem("token");
      console.log(token);
      if (token) {
        this.$router.push("orderList");
      } else {
        this.$message("请登录");
      }
    },
    goCar() {
      const token = sessionStorage.getItem("token");
      if (token) {
        this.$router.push("goodCar");
      } else {
        this.$message("请登录");
      }
    },
    toLogin() {
      this.$router.push("/login");
    },
  },
  created() {
    this.getGoodShop();
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  /* width: 100%; */
}
body {
  width: 100%;
}
.el-main {
  min-width: 1000px;
  overflow: visible;
}
#el-container {
  background-color: #f4f4f4;
}
.search-box {
  width: 100%;
  height: 10px;
  display: flex;
  margin-right: 4%;
  /* background-color: red; */
}
/* .shopcar-img {
  width: 28px;
  height: 28px;
  margin-top: 2px;
  margin-left: 3px;
} */
.shop-car p {
  display: block;
  font-size: 14px;
  line-height: 35px;
  margin-left: 10px;
  overflow: hidden;
}
.shop-car div {
  width: 30px;
  height: 20px;
  border-radius: 10px;
  background-color: rgb(255, 145, 0);
  margin-top: 7px;
  margin-left: 3px;
}
.shop-cara p {
  font-size: 14px;
  margin: auto;
  text-align: center;
  line-height: 20px;
  font-weight: 800;
  color: white;
}
.bottom-border {
  width: 100%;
  height: 50px;
  border-bottom: 3px solid red;
}
#el-main {
  width: 80%;
  margin: auto;
  margin-top: 175px;
}
.shop-car-list {
  width: 100%;
  /* height: 100%; */
  background-color: white;
}
.shop-car-title {
  width: 100%;
  height: 50px;
}
.shop-car-title-txt {
  font-size: 19px;
  line-height: 50px;
  margin-left: 20px;
}
.shop-car-nav {
  width: 100%;
  height: 40px;
  background-color: #f07676;
  display: flex;
}
.shop-car-one {
  width: 8%;
  height: 100%;
}
.checkbox-all {
  margin-left: 5px;
  line-height: 40px;
  font-size: 17px;
  color: white;
  user-select: none;
  cursor: pointer;
}
.checkbox-all-bottom {
  margin-left: 5px;
  line-height: 45px;
  font-size: 17px;
  color: black;
  user-select: none;
  cursor: pointer;
}
.shop-car-two {
  width: 35%;
  height: 100%;
}
.shop-car-three {
  width: 13%;
  height: 100%;
}
.shop-car-four {
  width: 15%;
  height: 100%;
}
.shop-car-five {
  width: 15%;
  height: 100%;
}
.shop-car-six {
  width: 15%;
  height: 100%;
}
.shop-car-line {
  display: flex;
  width: 100%;
  height: 130px;
  /* background-color: pink; */
  border-bottom: 1px solid #ddd;
}
.shop-car-line-box-img {
  width: 100px;
  height: 100px;
}
.shop-car-line-box-img img {
  width: 100px;
  height: 100px;
  margin-top: 15%;
  margin-left: 10px;
}
.shop-car-line-name {
  width: 75%;
  margin-left: 20px;
  margin-top: 12px;
  height: 120px;
}
.shop-name {
  font-size: 14px;
  width: 200px;
  display: inline-block;
}
.shop-car-onprice {
  width: 15%;
}
.shop-car-onprice-txt {
  line-height: 130px;
  margin-left: 36%;
  user-select: none;
}
.el-number {
  width: 15%;
  height: 130px;
  margin-left: 18px;
}
.num-stepper {
  margin-top: 48px;
  margin-left: 4px;
}
.shop-car-allprice {
  width: 19%;
}
.shop-car-allprice span {
  line-height: 130px;
  padding-left: 32px;
  user-select: none;
}
.shop-car-delete {
  width: 10%;
  height: 130px;
}
.shop-car-delete span {
  line-height: 130px;
  padding-left: 4%;
  cursor: pointer;
}

.shop-car-list-bottom {
  width: 100%;
  height: 45px;
  /* background: blueviolet; */
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}
.shop-delete-check {
  /* border:1px solid black; */
  width: 14%;
  height: 45px;
  margin-top: -2px;
  margin-left: -20px;
  display: flex;
}

.shop-delete-check-txt {
  line-height: 45px;
  font-size: 14px;
  margin-left: 10px;
  user-select: none;
  cursor: pointer;
}
.shop-delete-check-num {
  line-height: 45px;
  font-size: 14px;
  margin-left: 0px;
}
.shop-delete-check-allprice {
  /* line-height: 45px; */
  display: block;
  margin-top: 9px;
  font-size: 20px;
  margin-left: 10px;
  color: rgb(201, 25, 25);
}
.goPay {
  display: inline-block;
  width: 15%;
  height: 100%;
  background: #df3033;
  margin-left: 30%;
  text-align: center;
}
.gopay-button {
  display: inline-block;
  margin-top: 8px;
  font-size: 18px;
  font-family: "microsoft yahei";
  color: #fff;
  font-weight: 700;
  user-select: none;
  cursor: pointer;
}
.nothing-div {
  width: 100%;
  height: 500px;
  /* background-color: aqua; */
}
.nothing-back {
  display: block;
  margin: auto;
  padding-top: 7%;
}
.nothing-txt {
  font-size: 24px;
  color: #666666;
  margin-top: 30px;
  margin-left: 37%;
}
</style>